import "../assets/Home.scss";

import { Col, Divider, Flex, Row, Tag, Typography } from "antd";
import { getFrenchMonth, holidays, workingHours } from "../utils/getWorkingHours";

import ButtonRdv from "./ButtonRdv";
import { ClockCircleOutlined } from "@ant-design/icons";
import hero from "../assets/hero.jpg";

export default function Home() {
    const today = new Date();

    const isTodayHoliday = holidays.some(holiday => {
        const fromDate = new Date(today.getFullYear(), holiday.from.month, holiday.from.day);
        const toDate = new Date(today.getFullYear(), holiday.to.month, holiday.to.day);
        return today >= fromDate && today <= toDate;
    });
    console.log("🚀 ~ isTodayHoliday ~ isTodayHoliday:", isTodayHoliday);
    return (
        <section className="siteContent">
            <img style={{ width: "100%" }} src={hero} alt="Docteur Elisabeth Boulanger" />
            <p>Diplômée en 2015, j’ai travaillé en médecine générale en milieu rural pendant 3 ans. J’ai ensuite travaillé dans un service d’urgences en milieu hospitalier durant 5 ans. J’y ai ’acquis une solide connaissance des pathologies aiguës et des urgences médico-chirurgicales. J’ai aussi une bonne connaissance en nutrition...</p>
            <p>En tant que généraliste, j’aime le côté humain de la médecine de famille et ma préoccupation est votre santé.</p >
            <p>Je serai heureuse de vous accueillir sur rendez - vous selon les horaires repris ci - dessous.</p >
            <Divider />
            <Row gutter={[0, 30]}>
                <Col lg={24} xs={24}>
                    <Typography.Title level={2}><ClockCircleOutlined /> Horaires de consultation</Typography.Title>
                    {workingHours.map((day, index) => {
                        const isToday: boolean = new Date().getDay() - 1 === index;
                        return (
                            <div key={index}>
                                <Typography.Title level={4}>

                                    {isToday && <Tag style={{ fontSize: "20px", padding: ".3em" }} color="green">{day.day}</Tag>}
                                    {!isToday && day.day}
                                </Typography.Title>
                                <Typography.Paragraph>
                                    {day.open ? `Ouvert de ${day.start}h à ${day.end}h` : 'Fermé'}
                                    <br />
                                    <Typography.Text italic>{day.message}</Typography.Text>
                                </Typography.Paragraph>
                            </div>
                        )
                    })}

                </Col>
                <Col lg={24} xs={24}>
                    <Flex vertical gap="middle" align="center">

                        <Typography.Title level={2}><ClockCircleOutlined /> Fermetures</Typography.Title>
                        {/* {holidays.map((holiday, index) => {
                            const formattedFromDay: string = holiday.from.day === 1 ? 'er' : '';
                            const formattedToDay: string = holiday.to.day === 1 ? 'er' : '';
                            const isHolidayToday = new Date(today.getFullYear(), holiday.from.month, holiday.from.day) <= today &&
                                new Date(today.getFullYear(), holiday.to.month, holiday.to.day) >= today;

                            return (
                                <Typography.Paragraph key={index}>
                                    {(holiday.from.day === holiday.to.day) && (holiday.from.month === holiday.to.month)
                                    ? `Le ${holiday.from.day}${formattedFromDay} ${getFrenchMonth(holiday.from.month)}`
                                    : `Du ${holiday.from.day}${formattedFromDay} ${getFrenchMonth(holiday.from.month)} au ${holiday.to.day}${formattedToDay} ${getFrenchMonth(holiday.to.month)}`
                                }
                                {isHolidayToday && <Tag color="red" style={{ marginLeft: '10px' }}>Fermeture actuelle</Tag>}
                                </Typography.Paragraph>
                            )
                        })} */}
                        <Typography.Text strong>Pour tout renseignement concernant les périodes d’absence et de vacances, je vous invite à me poser la question directement et/ou à consulter régulièrement et attentivement le message de mon répondeur.</Typography.Text>
                        <ButtonRdv type="primary" />
                    </Flex>
                </Col>
            </Row>


            <Divider />
            <Typography.Title level={2}>Votre santé, ma préoccupation</Typography.Title>
            <p>En tant que médecin généraliste, je m’engage à :</p>
            <ul className="withBullet">
                <li><span>Être directement accessible</span></li>
                <li><span>Communiquer avec les patients et aussi avec mes confrères et mes consœurs généralistes et spécialistes sans oublier les paramédicaux</span></li>
                <li><span>Dispenser des soins de qualité basé sur des preuves scientifiques</span></li>
                <li><span>Effectuer une synthèse de toutes les variables de votre santé pour déterminer des priorités</span></li>
                <li><span>Utiliser le dossier médical global.</span></li>
            </ul>
            <p>Ensemble, nous nous occuperons de vos problèmes de santé dans une vision holistique. Ma tâche principale consistera à vous écouter et à vous interroger afin de comprendre ensemble, vos plaintes. L’examen clinique est une étape nécessaire et essentielle. La consultation se terminera éventuellement par la prescription pour des examens complémentaires afin d’affiner un diagnostic. Il s’agira ensuite d’instaurer un traitement.</p>
            <p>Le médecin généraliste occupe une place essentielle au sein du réseau de soins. Il a une bonne connaissance de son patient et de sa patiente, de son histoire, de sa famille et de son contexte de vie.</p>
            <p>Je suis membre de la <a href="https://www.ssmg.be/" target="_blank" rel="noreferrer">Société scientifique de médecine générale de Belgique</a>. Même après plusieurs années de formation, je continue à m’informe et à suivre une formation continuée.</p>
            <p>En tant que médecin généraliste, je me dois de respecter la déontologie c'est-à-dire l’ensemble des règles et devoirs inhérents à l’exercice de ma profession. Je respecte aussi l’éthique c'est-à-dire je communique des informations sérieuses et validées, indépendantes de tout finalité commerciale.</p>
            <ButtonRdv type="primary" />

        </section>
    )
}
